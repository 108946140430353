export default class HomeService {
    static alreadyImported = []

    constructor() {
        if ($('.home').length > 0) {
            if (!$('body').hasClass('eco-mode-active')) {
                // Strate events only
                if ($('[data-slider-trigger=".strate--events__highlights"]').length > 0) {
                    import(
                        '@scripts/components/StrateEventsHighlightsSlider.component' /* webpackChunkName: "scripts/StrateEventsHighlightsSlider.component" */
                    ).then(({ default: StrateEventsHighlightsSliderComponent }) => {
                        new StrateEventsHighlightsSliderComponent()
                    })
                }

                // Strate blog only
                if ($('[data-slider-trigger=".strate--blog"]').length > 0) {
                    import(
                        '@scripts/components/StrateBlogSlider.component' /* webpackChunkName: "scripts/StrateBlogSlider.component" */
                    ).then(({ default: StrateBlogSliderComponent }) => {
                        new StrateBlogSliderComponent()
                    })
                }
            }

            // Strate Activities
            import(
                '@scripts/components/StrateActivities.component' /* webpackChunkName: "scripts/StrateActivities.component" */
            ).then(({ default: StrateActivitiesComponent }) => {
                new StrateActivitiesComponent()
            })

            // breakpoint handler
            if (window.innerWidth > 1000) {
                HomeService.desktopHandler()
            } else {
                HomeService.mediumHandler()
            }
        }
    }

    // Desktop only imports
    static desktopHandler() {
        if (
            $("[data-element='svg-map']").length > 0 &&
            !HomeService.alreadyImported.includes('StrateMapDesktopComponent')
        ) {
            import(
                '@scripts/components/StrateMapDesktop.component' /* webpackChunkName: "scripts/StrateMapDesktop.component" */
            ).then(({ default: StrateMapDesktopComponent }) => {
                new StrateMapDesktopComponent()
            })
            HomeService.alreadyImported.push('StrateMapDesktopComponent')
        }

        if (
            $("[data-element='social-wall']").length > 0 &&
            !HomeService.alreadyImported.includes('StrateSocialComponent')
        ) {
            import(
                '@scripts/components/StrateSocial.component' /* webpackChunkName: "scripts/StrateSocial.component" */
            ).then(({ default: StrateSocialComponent }) => {
                new StrateSocialComponent()
            })
            HomeService.alreadyImported.push('StrateSocialComponent')
        }

        // When we're not on desktop anymore
        window.addEventListener('resize', function mediumOnly() {
            if (window.innerWidth < 1000) {
                HomeService.mediumHandler()
                window.removeEventListener('resize', mediumOnly)
            }
        })
    }

    // Medium only imports
    static mediumHandler() {
        // Strate map only
        let StrateMapSliderMedium
        if ($('.strate--map .strate__posts').length > 0) {
            import(
                '@scripts/components/StrateMapSliderMedium.component' /* webpackChunkName: "scripts/StrateMapSliderMedium.component" */
            ).then(({ default: StrateMapSliderMediumComponent }) => {
                StrateMapSliderMedium = new StrateMapSliderMediumComponent()
                StrateMapSliderMedium.swiperInstances.forEach((swiperInstance) =>
                    swiperInstance.init(),
                )
            })
        }

        // Strate events only
        let StrateEventsAgendaSliderMedium
        if ($('[data-slider-trigger=".strate--events__agenda"]').length > 0) {
            import(
                '@scripts/components/StrateEventsAgendaSliderMedium.component' /* webpackChunkName: "scripts/StrateEventsAgendaSliderMedium.component" */
            ).then(({ default: StrateEventsAgendaSliderMediumComponent }) => {
                StrateEventsAgendaSliderMedium = new StrateEventsAgendaSliderMediumComponent()
                StrateEventsAgendaSliderMedium.swiperInstance.init()
            })
        }

        // When we're not on medium anymore
        window.addEventListener('resize', function desktopOnly() {
            if (window.innerWidth > 1000) {
                StrateMapSliderMedium.swiperInstances.forEach((swiperInstance) =>
                    swiperInstance.destroy(),
                )
                StrateEventsAgendaSliderMedium.swiperInstance.destroy()
                HomeService.desktopHandler()
                window.removeEventListener('resize', desktopOnly)
            }
        })
    }
}
