/*
 * Created by IRIS Interactive
 * User : IRIS Interactive
 */

import HomeService from './services/home.service'

document.addEventListener('DOMContentLoaded', () => {
    new HomeService()

    // Booking engine
    if ($('.booking-engine__form').length > 0) {
        import(
            '@scripts/components/booking-engine.component' /* webpackChunkName: "scripts/booking-engine.component" */
        ).then(({ default: BookingEngineComponent }) => {
            new BookingEngineComponent()
        })
    }
})
